<template>
  <div class="lab">
    <template v-if="showMarketText">
      <div class="lab__market">
        {{ t('textToMarket') }}
      </div>
      <div class="lab__market --mobile">
        {{ t('textToMarketMobile') }}
      </div>
    </template>

    <div class="lab__picture">
      <div class="lab__top-content container-flex">
        <router-link
          to="/"
          class="lab__go-back ui-btn --style-3"
        >
          <IconArrowBack />
          <span class="lab__go-back-text">{{ t("toMainPage") }}</span>
        </router-link>

        <div class="lab__info">
          <div class="lab__subtitle">
            {{ subtitle }}
          </div>
          <div class="lab__title">
            {{ product.name }}
          </div>
        </div>

        <div class="lab__info-more">
          <LicenceInfo :product="product" />
          <a
            v-if="product.has_workbook && product.workbook_url"
            :href="product.workbook_url"
            class="lab__workbook"
            target="_blank"
          >
            <IconWorkbook class="lab__workbook-icon" />
            {{ t("labs.workbook") }}
          </a>
        </div>
      </div>
      <picture>
        <img
          :src="product.background_img || '/img/empty/placeholderBg.webp'"
          :alt="product.name"
          class="lab__background"
        />
      </picture>
      <Links
        class="lab__links-desktop"
        :product="product"
        @showMobileModal="isOpen = true;"
      />
    </div>


    <div class="lab__links-mobile-box">
      <div class="lab__subtitle-mob">
        {{ subtitle }}
      </div>
      <div class="lab__title-mob">
        {{ product.name }}
      </div>

      <Links
        class="lab__links-mobile"
        :product="product"
        @showMobileModal="isOpen = true;"
      />
    </div>

    <div class="container">
      <div
        v-if="product.authors_signature || product.description"
        class="lab__content"
      >
        <div
          v-if="product.authors_signature"
          class="lab__content-header"
        >
          <div class="lab__short">
            <div class="lab__short-icon">
              <InfoImportant />
            </div>
            <div
              class="lab__content-title"
              v-html="product.authors_signature"
            />
          </div>
        </div>
        <div
          v-if="product.description"
          class="lab__content-space lab__editor-content"
          v-html="product.description"
        />
      </div>

      <div
        v-if="product.supported_locales"
        class="lab__content"
      >
        <div class="lab__content-header">
          <div class="lab__short">
            <div class="lab__short-icon">
              <InfoGlobe />
            </div>
            <div class="lab__content-title">
              {{ t('fields.supported_locales') }}
            </div>
          </div>
        </div>
        <div class="lab__content-space lab__content-lang">
          <div
            v-for="lang in product.supported_locales"
            :key="`lang-${lang}`"
            class="lab__content-lang-item"
          >
            <span class="lab__content-lang-icon">
              <LangIcon :lang="lang" />
            </span>

            {{ t(`languages['${lang}']`) }}
          </div>
        </div>
      </div>

      <div
        v-if="videoInstructions.length"
        class="lab__content"
      >
        <div class="lab__content-header">
          <div class="lab__short">
            <div class="lab__short-icon">
              <InfoVideo />
            </div>
            <div class="lab__content-title">
              {{ t('labelVideos') }}
            </div>
          </div>
        </div>

        <div class="lab__content-space">
          <CarouselVideoCards
            class=""
            :list="videoInstructions"
            isVideo
          />
        </div>
      </div>

      <div
        v-if="usersGuides.length"
        class="lab__content"
      >
        <div class="lab__content-header">
          <div class="lab__short">
            <div class="lab__short-icon">
              <InfoDoc />
            </div>
            <div class="lab__content-title">
              {{ t('labelDocs') }}
            </div>
          </div>
        </div>
        <div class="lab__content-space">
          <div class="lab__documents">
            <a
              v-for="doc in usersGuides"
              :key="`doc-link-${doc.id}`"
              :href="doc.locales[currentLocaleIndex].file"
              target="_blank"
              class="lab__documents-link"
            >
              <div class="lab__documents-svg" />
              <div class="lab__documents-text">
                {{ doc.locales[currentLocaleIndex].caption }}
              </div>
            </a>
          </div>
        </div>
      </div>

      <div
        v-if="fieldSystemRequirements"
        class="lab__content"
      >
        <div class="lab__content-header">
          <div class="lab__short">
            <div class="lab__short-icon">
              <InfoSysReq />
            </div>
            <div class="lab__content-title">
              {{ t('fields.labelSystemReq') }}
            </div>
          </div>
        </div>
        <div
          class="lab__content-space lab__editor-content"
          v-html="fieldSystemRequirements"
        />
      </div>
    </div>
  </div>

  <MobileModal
    :isOpen="isOpen"
    @close="isOpen = false"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { catchErrorShow } from '@/utils';
import IconWorkbook from '@/components/icons/Workbook.vue';
import InfoImportant from '@/components/icons/InfoImportant.vue';
import LicenceInfo from '@/components/LicenceInfo.vue';
import InfoGlobe from '@/components/icons/InfoGlobe.vue';
import InfoVideo from '@/components/icons/InfoVideo.vue';
import InfoDoc from '@/components/icons/InfoDoc.vue';
import InfoSysReq from '@/components/icons/InfoSysReq.vue';
import MobileModal from '@/components/MobileModal.vue';
import IconArrowBack from '@/components/icons/ArrowBack.vue';
import { Manual, FileStatusEnum, DocumentTypeEnum, SystemReq } from '@/types/manuals';
import { createFieldByManuals } from '@/router/Admin/builderManuals';
import LangIcon from '@/components/Form/LangIcon.vue';
import Links from '@/router/Laboratories/components/Links.vue';
import { LabData, SysReq } from '@/types/LabData';
import CarouselVideoCards from '@/components/Carousel/CarouselVideoCards.vue';
// import fakeData from '@/assets/fakeData/laboratory';
import services from '../services';

const { t } = useI18n();

const props = defineProps({
  id: {
    type: Number,
    default: 0,
  },
});

const store = useStore();
const isSuAccessRole = computed(() => store.getters.isSuAccessRole);

const product = ref<LabData>({
  active_for_user: false,
  app_img: {
    original: '',
    '240x160': '',
    '400x280': '',
  },
  demo_counter: 0,
  demo_mode: false,
  description: '',
  authors_signature: '',
  system_requirements_type: SystemReq.Standard,
  system_requirements: '',
  id: null,
  license_state: null,
  self_license_state: false,
  name: '',
  offer: {
    id: null,
    price_per_month: null,
    price_per_month_with_discount: null,
    discount_per_month: null,
    type: '',
  },
  has_workbook: false,
  workbook_url: '',
  subsection: {
    shortname: '',
    name: '',
  },
  uuid: '',
  links: [],
  manuals: [],
  supported_locales: [],
  background_img: '',
});

const isOpen = ref(false);
const isAuthed = computed(() => store.getters.isAuthed);
const subsectionName = computed(() => (product.value?.subsection?.name ? `${product.value?.subsection?.name}` : ''));
const subtitle = computed(() => `${t('labs.virtualLab', { name: subsectionName.value })}`);
const userLang = store.getters.lang;
const sysReq = computed(() => {
  const data = store.getters['Common/systemRequirements'];
  return data.find((x: SysReq) => x.locale === userLang)?.text;
});

const showMarketText = computed(
  () => !isSuAccessRole.value && product.value.license_state === false
);

const fieldSystemRequirements = computed(
  () => (product.value.system_requirements_type === SystemReq.Standard
    ? sysReq.value
    : product.value.system_requirements)
);

const loadedManuals = computed(() => product?.value?.manuals
  .filter((item: Manual) => item.locales[0].file_status === FileStatusEnum.loaded) || []);

const videoInstructions = computed(() => (loadedManuals.value
  ? createFieldByManuals(loadedManuals.value, DocumentTypeEnum.Video)
    .sort((a, b) => a.order - b.order)
  : []));

const currentLocaleIndex = computed(() => store.getters['Locales/currentLocaleIndex']);

const usersGuides = computed(() => (loadedManuals.value
  ? createFieldByManuals(loadedManuals.value, DocumentTypeEnum.Document)
    .sort((a, b) => a.order - b.order)
  : []));

const launchFunction = async () => {
  store.commit('Common/SET_SHOW_LOADER', true);
  try {
    if (isAuthed.value) {
      product.value = await services.getLaboratoryById(props.id);
    } else {
      product.value = await services.getPublicLaboratoryById(props.id);
    }
    // product.value = fakeData;
  } catch (err) {
    catchErrorShow(err, t);
  } finally {
    store.commit('Common/SET_SHOW_LOADER', false);
  }
};

launchFunction();
</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.lab {

  &__market {
    color: white;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-top: 1px solid #E8665B;
    border-bottom: 1px solid #E8665B;
    background: linear-gradient(180deg, #E5625A 0%, #FD8263 50%);
    font-size: 14px;

    &.--mobile {
      display: none;
    }
  }

  &__picture {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    position: relative;
    margin-bottom: 24px;
    overflow: hidden;
    max-height: 700px;
  }

  &__top-content {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: end;
    padding-top: 53px;
    z-index: 1;
    color: $colorText1;
  }

  &__go-back {

    & svg {
      width: 16px;
      height: 24px;
    }
  }

  &__info {
    padding: 24px 44px 18px;
    background: $color-white;
    width: fit-content;
    max-width: 60%;
    border: 1px solid transparent;
    border-radius: 8px;
    background: $bgGrad1;
    box-shadow: 208px 109px 66px 0 rgba(9, 41, 57, 0.00), 133px 70px 60px 0 rgba(9, 41, 57, 0.01), 75px 39px 51px 0 rgba(9, 41, 57, 0.05), 33px 17px 38px 0 rgba(9, 41, 57, 0.09), 8px 4px 21px 0 rgba(9, 41, 57, 0.20);
  }

  &__info-more {
    display: flex;
    flex-direction: row;
    justify-content: end;
    column-gap: 8px;
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba($colorText1, .5);
    line-height: 1;
  }

  &__title {
    font-size: 44px;
    font-weight: 700;
    line-height: 1;
    text-align: right;
    padding-top: 10px;
    word-break: break-word;
  }

  &__workbook {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 5px 8px;
    font-size: 16px;
    background: $color-peach;
    color: $color-white;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid $colorGrayDisabled;

    &-icon {
      font-size: 24px;
    }
  }

  &__editor-content {
    li, ul, ol, p  {
      margin: revert;
    }

    a {
      text-decoration: underline;
      color: $colorBlueHover;
      transition: color .2s linear;

      &:hover {
        color: $colorBlue1;
      }
    }
  }

  &__content {
    margin: 12px auto;

    p {
      margin: 8px 0;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__content-lang {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
  }

  &__content-lang-item {
    @include flex-center;
    column-gap: 6px;
    background-color: $bgLight4;
    padding: 4px 12px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 600;
  }

  &__content-lang-icon {
    display: inline-flex;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    overflow: hidden;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__content-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid $colorBlue1;
    padding: 12px 32px;
    background-color: $bgLight4;
  }

  &__content-space {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 12px 60px 20px 76px;
    background-color: $color-white;

    &:only-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:has(.vue-video__arrow) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__short {
    display: flex;
    align-items: center;
  }

  &__short-icon {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    color: $colorBlue1;
    display: flex;
    align-items: center;

    svg {
      width: 28px;
      height: 27px;
    }
  }
  &__content-title {
    font-size: 20px;
    font-weight: 500;
  }

  &__background {
    height: 100%;
    left: 0;
    object-fit: cover;
    width: 100%;
  }

  &__modal-title {
    font-size: 26px;
    font-weight: 400;
    text-align: center;
  }

  &__modal-content {
    display: grid;
    gap: 10px;
    justify-content: center;
  }

  &__links-desktop {
    display: block;
  }

  &__links-mobile-box {
    display: none;
  }

  .vue-carousel {

    .carousel__slide {
      align-items: start;
    }

    .carousel__track {
      margin: 0;
    }
  }

  &__documents {
    padding: 0 13%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    gap: 14px;
  }

  &__documents-link {
    display: inline-flex;
    transition: color .2s;

    &:hover {
      color: $colorBlue1;
    }
  }

  &__documents-svg {
    flex-shrink: 0;
    display: inline-block;
    width: 18px;
    height: 20px;
    background: transparent url("@/assets/img/pdf.svg") no-repeat center / cover;
    margin-right: 10px;
  }

  &__documents-text {
    text-decoration: underline;
  }
}

@include media-breakpoint-down("tablet") {
  $commonGap: 24px;
  .lab {

    &__market {
      display: none;

      &.--mobile {
        display: block;
        height: 30px;
        line-height: 30px;
      }
    }

    .vue-carousel {

      .carousel {
        width: 100%;
      }

      .pagination {
        display: none;
      }

      &__arrow {
        display: none;
      }
    }

    &__content-header {
      padding-left: 16px;
      padding-right: 16px;
    }

    &__content-space {
      padding-left: 60px;
      padding-right: 40px;
    }

    &__links-mobile-box {
      display: block;
      padding: 24px $commonGap 0;
      background: linear-gradient(0, #FFF 10%, #DDD 90%);
      border-top: 1px solid white;
      border-bottom: 1px solid $colorGrayDisabled;
    }

    &__links-mobile {
      margin-top: $commonGap;
      position: static;
      display: block;
    }

    &__subtitle-mob {
      text-align: center;
    }

    &__title-mob {
      font-size: 21px;
      font-weight: 700;
      text-align: center;
    }

    &__links-desktop {
      display: none;
    }

    &__content {
      width: auto;
    }

    .ui-btn.--style-3 {
      padding: 4px 16px;
    }

    &__workbook {
      padding: 5px 8px;
    }

    &__picture {
      margin-bottom: 0;
      max-height: 460px;
    }

    &__top-content {
      padding: 18px $commonGap;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
    }

    &__info-more {
      flex-direction: column;
      align-items: end;
    }

    &__info {
      display: none;
    }

    &__documents {
      padding: 0;
    }

    &__documents-link {
      display: flex;
    }
  }
}

@include media-breakpoint-down("mobile") {
  $commonGap: 12px;

  .lab {

    &__documents {
      grid-template-columns: 1fr;
      gap: 0;
    }

    &__go-back-text {
      display: none;
    }

    .ui-btn.--style-3 {
      padding: 2px 14px;
      border-top: none;
    }

    &__workbook {
      padding: 1px 8px;
      font-size: 14px;
    }

    &__top-content {
      flex-wrap: wrap;
      padding: $commonGap;
    }

    &__content-header {
      padding-left: 12px;
      padding-right: 12px;
    }

    &__content-space {
      padding-left: 48px;
      padding-right: 20px;
    }

    &__links-mobile-box {
      padding: 24px $commonGap 0;
    }

    &__links-mobile {
      margin-top: $commonGap;
    }
  }
}

</style>
