import { RouteRecordRaw } from 'vue-router';

import close from './SessionClosed/router';
import notFound from './NotFound/router';
import accessDenied from './AccessDenied/router';
import temporaryUnavailable from './TemporaryUnavailable/router';

const alerts: Array<RouteRecordRaw> = [
  ...close,
  ...notFound,
  ...accessDenied,
  ...temporaryUnavailable,
];

export default alerts;
