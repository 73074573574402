import { createApp } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Maska from 'maska';
import 'nprogress/nprogress.css';
import '@/assets/style/main.scss';
import 'element-plus/dist/index.css';
import { setLocale } from '@/composition/useLangSet';
import { userAuthentication } from '@/router/middleware/auth';
import withIframeAuth from '@/utils/iframeKeycloakAuth';
import websocketConnect from '@/services/websocket';
import { getFallbackLocale } from '@/services/userActions';
import { i18n, defaultLocale } from '@/i18n';
import App from './App.vue';
// Redirect in router payment-success
import router, { getRoutes } from './router';
import store from './store';
import { mountApp } from './mountApp';

const setDefaultLocale = async () => {
  const locales = await getFallbackLocale(defaultLocale);
  i18n.global.fallbackLocale.value = [
    locales.locale || 'en',
    locales.fallback_locale || 'en',
    locales.grand_fallback_locale || 'en',
  ];
};

const launchFunc = async () => {
  await setDefaultLocale();

  let role = '';
  await withIframeAuth.then(async (flag) => {
    if (flag) {
      role = await userAuthentication(store);
    }
  });
  const routes: Array<RouteRecordRaw> = await getRoutes(role);
  routes.forEach((route: RouteRecordRaw) => router.addRoute(route));
  if (role?.length > 0) {
    await websocketConnect();
  }

  // Set app language
  setLocale();

  const app = createApp(App)
    .use(i18n)
    .use(Maska)
    .use(store)
    .use(router);

  mountApp(app);
};

await launchFunc();
