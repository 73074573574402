import { roles, allRoles, orgAccessRolesWithMarketer, suRoles } from './roles';

export const names = {
  profile: 'Profile',
  shop: 'Shop',
  invoices: 'Invoices',
  laboratories: 'Laboratories',
  laboratory: 'Laboratory',
  subscribe: 'Subscriptions',
  alerts: {
    closed: 'SessionClosed',
    notFound: 'NotFound',
    accessDenied: 'AccessDenied',
    temporaryUnavailable: 'TemporaryUnavailable',
  },
  errorsMock: 'ErrorsMock',
  addAnnouncement: 'AddAnnouncement',
  addProduct: 'addProduct',
  editProduct: 'EditProduct',
};

export const paths = {
  profile: '/profile/:type?',
  shop: '/shop',
  invoices: '/invoices',
  laboratories: '/',
  laboratory: '/laboratory/:id',
  subscribe: '/subscriptions',
  alerts: {
    closed: '/session-closed',
    notFound: '/:pathMatch(.*)*',
    accessDenied: '/access-denied',
    temporaryUnavailable: '/temporary-unavailable',
  },
  errorsMock: '/errors-mock',
  addAnnouncement: '/add-announcement',
  addProduct: '/add-product',
  editProduct: '/edit-product/:id',
};

export const permissions = {
  profile: [
    roles.admin,
    roles.moderator,
    roles.marketer,
    roles.student,
    roles.teacher,
    roles.orgOwner,
    roles.orgModerator,
    roles.distributor,
  ],
  addEditProduct: [
    roles.admin,
    roles.moderator,
    roles.marketer,
  ],
  laboratories: allRoles,
  laboratory: allRoles,
  alerts: allRoles,
  subscribe: [roles.student, roles.teacher, roles.marketer],
  shop: [
    roles.marketer,
    roles.student,
    roles.teacher,
    roles.distributor,
    roles.guest,
  ],
  price: [
    roles.marketer,
    ...suRoles,
  ],
  invoices: [
    roles.orgOwner,
    roles.orgModerator,
  ],
  administration: {
    root: orgAccessRolesWithMarketer,
  },
};
