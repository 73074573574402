<template>
  <ModalMenu
    class="nav-mobile"
    :class="{ isShow: isShow }"
    :isShow="isShow"
    @close="handleClose"
  >
    <template #button="{ buttonClass }">
      <button
        class="nav-mobile__toggle-btn"
        :class="buttonClass"
        @click="handleToggle"
      >
        {{ t(firstLevelMenuTitle) }}

        <IconArrow class="nav-mobile__toggle-icon" />
      </button>
    </template>

    <template #content>
      <template
        v-for="(element, index) in menu"
        :key="`navLink${index}`"
      >
        <ModalMenuItem
          v-if="element.title !== firstLevelMenuTitle"
          :to="element.link"
          :link="element.baseUrlLink"
          @click="handleClose"
        >
          {{ t(element.title) }}
        </ModalMenuItem>
      </template>
    </template>
  </ModalMenu>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { mainMenu, MenuItem } from '@/constants/menus';
import { permissions } from '@/constants/routes';
import ModalMenu from '@/components/Header/ModalMenu.vue';
import ModalMenuItem from '@/components/Header/ModalMenuItem.vue';
import IconArrow from '@/components/icons/CarouselLeft.vue';

const store = useStore();
const route = useRoute();
const { t } = useI18n();

const userRole = computed<string>(() => store.getters.role);

const isShow = ref<boolean>(false);

const menu = computed<Array<MenuItem>>(
  () => mainMenu.filter(
    (menuLink) => menuLink.permissions.includes(userRole.value)
        && menuLink.permissions !== permissions.administration.root
  )
);

const handleToggle = (): void => {
  isShow.value = !isShow.value;
};

const handleClose = (): void => {
  isShow.value = false;
};

const firstLevelMenuTitle = computed(() => {
  const routeMatchedNames = route.matched.map(
    (matchedRoute) => matchedRoute.name
  );

  const currentMenuItem = menu.value.find((menuItem) => {
    if (typeof menuItem.link === 'string' || !('name' in menuItem.link)) {
      return false;
    }

    return routeMatchedNames.includes(menuItem.link.name);
  });

  return currentMenuItem?.title || 'portal';
});
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";

.nav-mobile {
  min-width: 156px;
  height: 100%;
  white-space: nowrap;
  position: relative;

  &.isShow {
    box-shadow: $dropdownBoxShadow;

    .nav-mobile__toggle-icon {
      transform: rotate(-90deg) scaleX(-1) translate(3px, 6px);
    }

    .nav-mobile__toggle-btn {
      color: $color-white;
      background-color: $colorBlue1;
    }
  }

  &__toggle-btn {
    width: 100%;
    height: inherit;
    padding-left: 12px;
    padding-right: 18px;
    font-family: "Noto Sans", sans-serif;
    font-weight: 600;
    color: $colorText1;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__toggle-icon {
    transform: rotate(-90deg) translate(-3px, 6px);
    transition: transform $transition-speed;
  }
}
</style>
