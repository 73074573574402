<template>
  <div
    ref="$el"
    class="lang-switcher"
  >
    <Dropdown
      :show="isShow"
      :indent="0"
      right
      class="lang-switcher__dropdown"
    >
      <template #trigger>
        <div
          class="lang-switcher__trigger nav-link__animated-bg"
          :class="{ active: isShow }"
          @click="handleToggle"
        >
          <div class="lang-switcher__round">
            <div :class="`flag flag-${currentLang}`" />
          </div>

          <span class="lang-switcher__trigger-lang">
            {{ localesNamesMapper[currentLang] }}
          </span>
        </div>
      </template>

      <template
        v-for="lang in availableLocales"
        :key="lang"
      >
        <CleanButton
          v-if="currentLang !== lang"
          class="lang-switcher__dropdown-button"
          :disabled="currentLang === lang"
          @click="handleLangSelect(lang)"
        >
          <div class="lang-switcher__round">
            <div :class="`flag flag-${lang}`" />
          </div>

          <span class="lang-switcher__name">{{ localesNamesMapper[lang] }}</span>
        </CleanButton>
      </template>
    </Dropdown>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { i18n } from '@/i18n';
import { localesNamesMapper } from '@/constants/languages';
import { availableLocales, setLocale } from '@/composition/useLangSet';
import { useClickOutside } from '@/libraries/useClickOutside';

import Dropdown from '@/components/Dropdown.vue';
import CleanButton from '@/components/CleanButton.vue';

const router = useRouter();

const $el = ref<HTMLDivElement | null>(null);
const isShow = ref(false);
const currentLang = computed(() => i18n.global.locale.value);

const handleToggle = () => {
  isShow.value = !isShow.value;
};
const handleClose = () => {
  isShow.value = false;
};
const handleLangSelect = (lang: string) => {
  setLocale(lang);
  handleClose();
  router.go(0);
};

useClickOutside($el, handleClose);
</script>

<style lang="scss">
@import "@/assets/style/include";

.lang-switcher {
  height: 100%;
  display: flex;
  align-items: center;

  .dropdown {
    height: 100%;
  }

  &__round {
    $roundSize: 32px;
    width: $roundSize;
    height: $roundSize;
    overflow: hidden;

    .flag {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .flag-ru {
      background-image: url("@/assets/svg/lang/ru.svg");
    }
    .flag-en {
      background-image: url("@/assets/svg/lang/en.svg");
    }
    .flag-fr {
      background-image: url("@/assets/svg/lang/fr.svg");
    }
    .flag-hy {
      background-image: url("@/assets/svg/lang/hy.svg");
    }
  }

  svg {
    transform: scale(1.9);
  }

  &__trigger {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 24px 0 12px;
    cursor: pointer;
    transition:
        color $transition-speed,
        box-shadow $transition-speed ease-in;

    &:hover,
    &.active {
      color: $color-white;
    }

    &.active {
      background-color: $colorBlue1;
      box-shadow: $dropdownBoxShadow;
    }
  }
  &__trigger-lang {
    margin-left: 8px;
  }
  &__dropdown {
    padding: 0;
  }

  &__dropdown-button {
    align-items: center;
    display: inline-flex;
    padding: 8px 12px;
    gap: 8px;
    white-space: nowrap;
    width: 100%;

    &:hover,
    &.active {
      color: $color-white;
    }

    &:hover {
      background-color: $colorBlueHover;
    }

    &.active {
      background-color: $colorBlue1;
      cursor: default;
    }

    & > svg {
      margin-left: 0.5rem;
    }
  }

  &__dropdown {
    width: 100%;
    left: 0;
    position: absolute;
    border-radius: 0 0 16px 16px;
    background-color: $bgLight1;
  }
}

@include media-breakpoint-down("tablet") {
  .lang-switcher {
    margin-right: -$containerPaddingFlexTablet;

    &__trigger {
      padding: 8px 24px;
    }

    &__trigger-lang {
      display: none;
    }

    &__dropdown {
      border-bottom-right-radius: 0;
    }

    &__dropdown-button {
      justify-content: center;
    }

    &__name {
      display: none;
    }
  }
}

@include media-breakpoint-down("mobile") {
  .lang-switcher {
    margin-right: -$containerPaddingFlexMobile;

    &__round {
      $roundSize: 44px;
      width: $roundSize;
      height: $roundSize;
    }

    &__trigger {
      padding: 0 12px;
    }
  }
}
</style>
