<template>
  <div class="avatar">
    <Icon v-if="!avatarLink" />
    <img
      v-else
      :alt="t('imagesAlt.avatar')"
      :src="avatarLink"
    />
  </div>
</template>

<script setup lang="ts">
import IconUser from '@/components/icons/User.vue';
import IconUserMobile from '@/components/icons/UserMobile.vue';

const props = defineProps<{
  avatarLink?: string | null;
  isMobile?: boolean;
}>();

const Icon = props.isMobile ? IconUserMobile : IconUser;
</script>

<style lang="scss">
@import "@/assets/style/variables.scss";

.avatar {
  align-items: center;
  background: $header-background;
  border-radius: 50%;
  border: 1px solid black;
  color: black;
  display: flex;
  justify-content: center;
  transition: $transition-speed;

  img, svg {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}

.active {
  .avatar {
    color: white;
    border-color: white;
    background: $colorBlue1;
  }
}

</style>
