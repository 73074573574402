<template>
  <nav class="nav">
    <template
      v-for="(element, index) in menu"
      :key="`navLink-${index}`"
    >
      <router-link
        v-if="!element.baseUrlLink"
        :to="element.link"
        class="nav__link nav-link__animated-bg"
      >
        {{ t(element.title) }}
      </router-link>
      <a
        v-else
        :href="element.baseUrlLink"
        class="nav__link nav-link__animated-bg"
      >
        {{ t(element.title) }}
      </a>
    </template>
  </nav>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { mainMenu, MenuItem } from '@/constants/menus';

const store = useStore();
const { t } = useI18n();

const userRole = computed<string>(() => store.getters.role);
const user = computed(() => store.getters.user || '');

const menu = computed<Array<MenuItem>>(() => {
  let items = [];
  items = mainMenu.filter(
    (menuLink) => menuLink.permissions.includes(userRole.value)
  );

  if (!user.value) { return items; }
  const idOrg = user.value?.organization?.id || null;
  if (!idOrg) { return items; }

  return items.map((menuLink) => {
    if (menuLink.baseUrlLink?.includes('invoices')) {
      const updatedMenuLink = { ...menuLink };
      updatedMenuLink.baseUrlLink = menuLink.baseUrlLink?.replace('invoices', `organization/${idOrg}/orders`);
      return { ...updatedMenuLink };
    }
    return menuLink;
  });
});
</script>

<style scoped lang="scss">
@import "@/assets/style/include";

.nav {
  display: flex;
  overflow-y: auto;
}

.nav__link {
  align-items: center;
  background: $color-transparent;
  box-shadow: inset 0 -3px 0 $color-transparent;
  display: inline-flex;
  height: $header-height;
  padding: 22px 20px;
  transition: color $transition-speed;

  &:hover:not(.active) {
    color: $color-white;
  }

  @include media-breakpoint-down("sm") {
    height: $header-height-mobile;
    padding: 22px 8px;
  }
}

.nav__link.active {
  background: linear-gradient(180deg, #ECF4F8 0%, #E0EAF1 100%);
  box-shadow: inset 0 -3px 0 $color-cyan-menu;
  font-weight: 700;
}

.nav__link.active.exact {
  cursor: default;
}
</style>
