<template>
  <div
    ref="$el"
    class="user-profile"
  >
    <Dropdown
      :show="isShow"
      :indent="0"
      right
      class="user-profile__dropdown"
    >
      <template #trigger>
        <div
          class="user-profile__trigger nav-link__animated-bg"
          :class="{ active: isShow }"
          @click="handleToggle"
        >
          <h3 class="user-profile__name">
            <span> {{ firstName }} </span>
            <span v-if="secondName">&nbsp;{{ secondName }}. </span>
          </h3>

          <UserAvatar
            :avatarLink="userData.small_avatar"
            class="user-profile__avatar"
          />
        </div>
      </template>

      <div class="user-profile__dropdown-content">
        <div class="user-profile__role">
          {{ displayedRole }}
        </div>

        <router-link
          :to="{ name: names.profile }"
          class="user-profile__link"
          @click="handleClose"
        >
          {{ t("menus.profile") }}
        </router-link>

        <CleanButton
          class="user-profile__link"
          :disabled="isDisabledLogBtn || showLoader"
          @click="handleLogout"
        >
          {{ t("menus.logout") }}
        </CleanButton>
      </div>
    </Dropdown>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { names } from '@/constants/routes';
import { roles } from '@/constants/roles';
import { useClickOutside } from '@/libraries/useClickOutside';

import Dropdown from '@/components/Dropdown.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import CleanButton from '@/components/CleanButton.vue';

import { logoutUser } from '@/services/userActions';
import { LoggedUser } from '@/types/user';

const store = useStore();

const $el = ref<HTMLDivElement | null>(null);
const isShow = ref(false);
const { t } = useI18n();

const emit = defineEmits<{
  (e: 'lockLogin', val: boolean): void;
}>();

const userData = computed<LoggedUser>(() => store.state.UserData.user);
const firstName = computed<string>(() => {
  if (userData.value.firstname && userData.value.firstname?.length > 16) {
    return `${userData.value.firstname?.slice(0, 16)}...`;
  }
  return userData.value.firstname || '';
});

const secondName = computed<string>(() => (userData.value.surname || '').charAt(0));

const displayedRole = computed<string>(() => {
  const roleData = userData.value.role;
  if (roleData.shortname === roles.student && !userData.value.organization) {
    return t('roles.user');
  }
  return t(`roles.${roleData.shortname}`);
});

const handleToggle = (): void => {
  isShow.value = !isShow.value;
};

const handleClose = (): void => {
  isShow.value = false;
};

const handleLogout = async (): Promise<void> => {
  disableLoginBtn();
  handleClose();
  await logoutUser();
  await store.dispatch('clearUserData');
};

const isDisabledLogBtn = ref(false);
const showLoader = computed(() => store.getters['Common/showLoader']);

const disableLoginBtn = () => {
  isDisabledLogBtn.value = true;
  emit('lockLogin', true);
};

useClickOutside($el, handleClose);
</script>

<style scoped lang="scss">
@use "sass:math";
@import "@/assets/style/include.scss";

$paddingH: 20px;

.user-profile {
  min-width: 132px;
  height: 100%;
}

:deep(.user-profile__dropdown),
.user-profile__trigger.active {
  box-shadow: $dropdownBoxShadow;
}

:deep(.user-profile__dropdown) {
  width: 100%;
  left: 0;
  position: absolute;
  border-radius: 0 0 16px 16px;
  background-color: $bgLight1;
}

.user-profile__trigger {
  align-items: center;
  display: flex;
  padding: 1rem $paddingH;
  position: relative;
  cursor: pointer;
  transition:
      color $transition-speed,
      height $transition-speed,
      box-shadow $transition-speed ease-in;;

  &:hover,
  &:hover .avatar,
  &.active {
    color: $color-white;
  }

  &:hover .avatar {
    border-color: $color-white;
    background-color: $colorBlue1;
  }

  &.active {
    height: 100%;
    background-color: $colorBlue1;
  }
}

.user-profile__name + .user-profile__avatar {
  margin-left: 10px;
}

.user-profile__name {
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1rem;
  font-weight: 400;
}

.user-profile__avatar {
  flex-shrink: 0;
  font-size: 1.15rem;
  height: 2rem;
  width: 2rem;
}

.user-profile__dropdown-content {
  margin: -#{math.div($base-indent, 2)} -#{math.div($base-indent, 4)};
}

.user-profile__role {
  background-color: $colorBlue1;
  color: white;
  font-size: 12px;
  padding: 0.25rem $paddingH;
  border-bottom: 2px solid $colorBluePressed;
}

.user-profile__link {
  cursor: pointer;
  display: block;
  padding: 12px $paddingH;
  text-align: left;
  width: 100%;
  color: $colorText1;

  &.active {
    pointer-events: none;
  }

  &:hover {
    background-color: $colorBlueHover;
  }

  &:hover,
  &.active {
    color: $color-white;
  }

  &:focus-visible,
  &:active,
  &.active {
    background-color: $colorBluePressed;
  }
}

@include media-breakpoint-down("mobile") {
  .user-profile {
    margin-right: -$containerPaddingFlexMobile;

    .dropdown {
      height: 100%;
    }
  }

  .user-profile__trigger {
    justify-content: flex-end;
    height: 100%;

    button {
      font-size: 12px;
    }
  }

  .user-profile__name {
    display: none;
  }

  .user-profile__avatar {
    width: 2.5rem;
    height: 2.5rem;
  }

  :deep(.user-profile__dropdown) {
    border-bottom-right-radius: 0;
  }
}

</style>
