<template>
  <header :class="['header', { mobile: isMobileScreen }]">
    <div class="container-flex">
      <a
        :href="companyWebsiteLink"
        class="header__logo-link"
        target="_blank"
        :aria-label="t('vrLabs')"
      >
        <AppLogo />
      </a>

      <TransitionFade>
        <HeaderNav class="header__nav" />
      </TransitionFade>

      <UserProfile
        v-if="userData"
        @lockLogin="isDisabledLogBtn = true"
      />

      <div
        v-else
        class="header__right"
      >
        <CleanButton
          class="header__login nav-link__animated-bg"
          :disabled="isDisabledLogBtn || showLoader"
          @click="handleLogin"
        >
          <IconLogin
            class="header__login-icon"
            aria-hidden="true"
          />

          <span :class="{ 'visually-hidden': isMobileScreen }">
            {{ t("menus.login") }}
          </span>
        </CleanButton>

        <LangSwitcher />
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useScreenSize } from '@/composition/useScreenSize';
import { loginUser as handleLogin } from '@/services/userActions';
import { LoggedUser } from '@/types';
import { companyWebsiteLink } from '@/utils/constTemplates';
import AppLogo from '@/components/AppLogo.vue';
import CleanButton from '@/components/CleanButton.vue';
import TransitionFade from '@/components/TransitionFade.vue';
import HeaderNavMobile from '@/components/Header/Nav/Mobile.vue';
import HeaderNavDesktop from '@/components/Header/Nav/Desktop.vue';
import UserProfile from '@/components/Header/UserProfile.vue';
import LangSwitcher from '@/components/Header/LangSwitcher.vue';
import IconLogin from '@/components/icons/Menu/Login.vue';

const store = useStore();
const { t } = useI18n();
const { isMobileScreen } = useScreenSize();

const userData = computed<LoggedUser>(() => store.state.UserData.user);
const isDisabledLogBtn = ref(false);
const showLoader = computed(() => store.getters['Common/showLoader']);

const HeaderNav = computed(
  () => (isMobileScreen.value ? HeaderNavMobile : HeaderNavDesktop)
);
</script>

<style scoped lang="scss">
@use "sass:map";
@import "@/assets/style/include";

.header {
  background-color: $header-background;
  height: $header-height;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: $header-index;
  transition: background-color 1s;
  box-shadow: 0 4px 10px 0 #1D3F4D26;
  color: $colorText1;

  .container-flex {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }

  &.mobile {
    background-color: #F5F8FA;

    .header__login {
      flex-shrink: 0;
      width: 44px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &:not(.mobile) {
    .header__login {
      padding: 0 12px;
    }
  }

  &__logo-link {
    display: inline-flex;
    font-size: 44px;
  }

  &__login {
    color: $colorText1;
    height: 100%;
    transition: color $transition-speed;

    @include hover-focus() {
      color: $color-white;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: stretch;
    height: 100%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity $transition-speed ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@include media-breakpoint-up("mobile") {
  .header__login-icon {
    display: none;
  }
}

@include media-breakpoint-down("mobile") {
  .header {
    height: $header-height-mobile;

    &__logo-link {
      font-size: 44px;
    }

    &__nav {
      flex-grow: 1;
    }
  }
}
</style>
