<template>
  <div class="side-menu">
    <ElTooltip
      popperClass="side-menu__tooltip"
      effect="light"
      :content="t('addApp')"
      rawContent
      placement="left"
    >
      <div
        class="side-menu__item"
        @click="showCreateMenu = true"
      >
        <IconPlus />
      </div>
    </ElTooltip>

    <ElTooltip
      popperClass="side-menu__tooltip"
      effect="light"
      :content="t('commonVideo')"
      rawContent
      placement="left"
    >
      <div
        class="side-menu__item"
        @click="isVideoDialogVisible = true"
      >
        <IconVideos />
      </div>
    </ElTooltip>

    <ElTooltip
      popperClass="side-menu__tooltip"
      effect="light"
      :content="t('commonDocs')"
      rawContent
      placement="left"
    >
      <div
        class="side-menu__item"
        @click="isDocsDialogVisible = true"
      >
        <IconDocs />
      </div>
    </ElTooltip>

    <ElTooltip
      popperClass="side-menu__tooltip"
      effect="light"
      :content="t('systemReqs')"
      rawContent
      placement="left"
    >
      <div
        v-if="!isMarketer"
        class="side-menu__item"
        @click="isSystemReqVisible = true"
      >
        <IconSystemReqs />
      </div>
    </ElTooltip>

    <div
      v-show="showCreateMenu"
      class="side-menu__add"
    >
      <div class="side-menu__add-top">
        <div class="side-menu__add-title">
          {{ t('createProductCard') }}
        </div>
        <div
          class="side-menu__add-close"
          @click="showCreateMenu = false"
        >
          <IconCross />
        </div>
      </div>
      <div class="side-menu__add-cards">
        <component
          :is="isMarketer ? 'span' : 'router-link'"
          :to="paths.addProduct"
          class="side-menu__add-card"
          :class="{ locked: isMarketer }"
        >
          <div class="side-menu__add-card-name">
            {{ t('shop.product') }}
          </div>
          <div class="side-menu__add-card-img" />
          <div class="side-menu__add-card-text">
            {{ t('someText1') }}
          </div>
          <ElTooltip
            v-if="isMarketer"
            effect="light"
            :content="t('tooltipLockNewProduct')"
            rawContent
            popperClass="side-menu__add-card-tooltip"
          >
            <div>
              <IconSystemReqs />
            </div>
          </ElTooltip>
        </component>
        <router-link
          :to="paths.addAnnouncement"
          class="side-menu__add-card"
        >
          <div class="side-menu__add-card-name">
            {{ t('labelAnnouncement') }}
          </div>
          <div class="side-menu__add-card-img" />
          <div class="side-menu__add-card-text">
            {{ t('someText2') }}
          </div>
        </router-link>
      </div>
    </div>

    <ManualsGeneral
      v-if="isVideoDialogVisible"
      v-model="isVideoDialogVisible"
      :type="DocumentTypeEnum.Video"
      @close="isVideoDialogVisible = false"
    />

    <ManualsGeneral
      v-if="isDocsDialogVisible"
      v-model="isDocsDialogVisible"
      :type="DocumentTypeEnum.Document"
      @close="isDocsDialogVisible = false"
    />
  </div>

  <DialogSystemReq
    v-if="isSystemReqVisible"
    v-model="isSystemReqVisible"
    @close="isSystemReqVisible = false"
  />

  <div
    v-show="showCreateMenu"
    class="side-menu__backdrop"
    @click="showCreateMenu = false"
  />
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { ElTooltip } from 'element-plus';
import { useStore } from 'vuex';
import IconPlus from '@/components/icons/Plus.vue';
import IconVideos from '@/components/icons/Videos.vue';
import IconDocs from '@/components/icons/Docs.vue';
import IconSystemReqs from '@/components/icons/SystemReqs.vue';
import IconCross from '@/components/icons/Cross.vue';
import { paths } from '@/constants/routes';
import { DocumentTypeEnum } from '@/types/manuals';
import ManualsGeneral from '@/components/Form/ManualsGeneral.vue';
import DialogSystemReq from '@/components/Form/DialogSystemReq.vue';

const { t } = useI18n();
const store = useStore();
const isMarketer = computed(() => store.getters.isMarketer);

const showCreateMenu = ref(false);
const isVideoDialogVisible = ref(false);
const isDocsDialogVisible = ref(false);
const isSystemReqVisible = ref(false);

watch(isVideoDialogVisible, () => {
  showCreateMenu.value = false;
});
watch(isDocsDialogVisible, () => {
  showCreateMenu.value = false;
});
watch(isSystemReqVisible, () => {
  showCreateMenu.value = false;
});
</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.side-menu {
  $cardRadius: 12px;

  position: fixed;
  z-index: 10;
  top: 300px;
  right: 0;
  background: $colorBlue1;
  padding: 6px;
  border-radius: 16px 0 0 16px;
  color: white;
  box-shadow: 2px 4px 4px 0 #00000038;

  &__backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5;
    background: rgba(0, 0, 0, 0.5);
  }

  &__tooltip {
    font-weight: 500;
    font-size: 13px;
  }

  &__item {
    padding: 15px;
    cursor: pointer;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 18px;
      height: 18px;
    }

    &:hover {
      background: $colorBlueHover;
    }
    &:active {
      background: $colorBluePressed;
    }
  }

  &__add {
    position: absolute;
    left: -25px;
    top: 0;
    transform: translate(-100%, -30%);
    padding: 32px;
    background: $colorBlue1;
    border-radius: 22px;
  }
  &__add-top {
    display: flex;
    justify-content: space-between;
    color: white;
    align-items: center;
  }
  &__add-title {
    font-size: 26px;
    font-weight: 500;
  }
  &__add-close {
    width: 42px;
    height: 42px;
    line-height: 42px;
    cursor: pointer;
    border-radius: $cardRadius;
    text-align: center;

    &:hover {
      background: $colorBlueHover;
    }

    svg {
      transform: translateY(2px);
    }
  }
  &__add-cards {
    display: flex;
    gap: 22px;
    margin-top: 38px;
  }
  &__add-card {
    width: 308px;
    background: white;
    border-radius: $cardRadius;
    font-size: 16px;
    border: 1px solid $colorBlue1;
    transition: .2s;
    position: relative;

    &:hover {
      border: 1px solid $colorBlueHover;
    }

    &:nth-child(1) {
      & .side-menu__add-card-img {
        background: url("@/assets/img/card.jpg");
      }
    }
    &:nth-child(2) {
      & .side-menu__add-card-img {
        background: url("@/assets/img/announcement.jpg");
      }
    }

    &-tooltip {
      text-align: center;
    }

    &.locked {
      overflow: hidden;
      cursor: pointer;

      &:before {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        content: '';
        background: rgba(255, 255, 255, 0.7);
      }
    }
  }
  &__add-card-name {
    height: 44px;
    color: $colorBlue1;
    font-weight: 700;
    padding: 0 20px;
    line-height: 44px;
    text-transform: uppercase;
  }
  &__add-card-img {
    height: 180px;
  }
  &__add-card-text {
    height: 84px;
    color: $colorText1;
    font-weight: 400;
    padding: 12px 20px;
  }
}

@include media-breakpoint-down("tablet") {
  .side-menu {
    display: none;
  }
}

</style>
