import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n';
import { Lang } from '@/types/manuals';
import { getLocalKeys } from './utils/i18n/localesFunc';

/** import
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
const loadLocaleMessages = (): LocaleMessages<VueMessageType> => {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: LocaleMessages<VueMessageType> = {};

  getLocalKeys(locales).forEach((key: string) => {
    // Can change locale
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
};

const storageLang = localStorage.getItem('vr_lang');

export const fallbackMap = {
  en: ['en', 'en', 'en'],
  ru: ['ru', 'en', 'en'],
  fr: ['fr', 'en', 'en'],
  hy: ['hy', 'ru', 'en'],
};

export const defaultLocale = storageLang
  ? JSON.parse(storageLang) as Lang
  : navigator.language.split('-')[0] as Lang || 'en';

export const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: fallbackMap[defaultLocale] || 'en',
  messages: loadLocaleMessages(),
});
