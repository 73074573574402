import { VueI18n } from 'vue-i18n';
import { AxiosError } from 'axios';
import { toast } from '@/utils/toast';

import { ErrorCodeEnum } from '@/types/httpResponses';

const isRecord = (
  value: unknown
): value is Record<string, unknown> => typeof value === 'object' && value !== null;

const isAxiosError = <T>(error: unknown): error is AxiosError<T> => {
  const isError = error instanceof Error;
  return isError
    && isRecord(error)
    && typeof error.isAxiosError === 'boolean';
};

export const catchErrorShow = (error: any, t: VueI18n['t']): void => {
  if (error.response.status === ErrorCodeEnum.NotFound) {
    return;
  }

  let message: string | undefined;

  if (isAxiosError<{ message?: string }>(error)) {
    message = error.response?.data?.message;
  }
  toast.error(message || t('somethingWentWrong'));
  console.error(error);
};

export default {
  catchErrorShow,
};
