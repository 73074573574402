<template>
  <svg
    v-bind="attrs"
    fill="none"
  >
    <path
      d="M22.1304 36.6654V34.832H33.8706C34.1529 34.832 34.4116 34.7145 34.6466 34.4796C34.8815 34.2446 34.999 33.9859 34.999 33.7036V10.2938C34.999 10.0114 34.8815 9.7528 34.6466 9.51782C34.4116 9.28285 34.1529 9.16536 33.8706 9.16536H22.1304V7.33203H33.8706C34.7146 7.33203 35.4192 7.61467 35.9844 8.17995C36.5497 8.74523 36.8324 9.44984 36.8324 10.2938V33.7036C36.8324 34.5476 36.5497 35.2522 35.9844 35.8174C35.4192 36.3827 34.7146 36.6654 33.8706 36.6654H22.1304ZM20.826 28.486L19.539 27.1673L23.7909 22.9154H7.49902V21.082H23.7909L19.539 16.8301L20.826 15.5114L27.3132 21.9987L20.826 28.486Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 44, height: 44 });
</script>
