<template>
  <a
    v-if="linkLaunch"
    class="launch-product"
    :href="linkLaunch"
    target="_blank"
    @click.stop="handlePlay"
  >
    <IconPlay />
  </a>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { ProductStateEnum } from '@/types/enums';
import { catchErrorShow, isMobile } from '@/utils';
import services from '@/router/Laboratories/services';
import { LabData } from '@/types/LabData';
import IconPlay from '@/components/icons/Play.vue';

const props = defineProps<{
  card: LabData;
  disableStorageUpdate?: boolean;
}>();

const { t } = useI18n();
const store = useStore();
const isGuest = computed(() => store.getters.isGuest);
const isSuAccessRole = computed(() => store.getters.isSuAccessRole);
const isDraft = computed(() => props.card.state === ProductStateEnum.Draft);
const isMobileConst = isMobile();
const canLaunch = computed(() => props.card.active_for_user);
const canLaunchDemo = computed(() => props.card.demo_mode && Boolean(props.card.demo_counter));

const linkLaunch = computed(() => {
  if (
    isGuest.value
      || isDraft.value
      || isMobileConst
      || (!isSuAccessRole.value && !props.card.license_state)
      || !props.card?.uuid
      || !(canLaunch.value || canLaunchDemo.value)
  ) {
    return '';
  }

  return props.card.demo_mode
    ? `/api/product/launch-as-demo/${props.card.uuid}?is_mobile=${Number(isMobileConst)}`
    : `/api/product/launch/${props.card.uuid}?is_mobile=${Number(isMobileConst)}`;
});

const handlePlay = async () => {
  if (!props.disableStorageUpdate) {
    store.commit('Common/ADD_RECENT', props.card.id);
  }

  if (!isMobileConst || !props.card.uuid) {
    return;
  }

  try {
    await services.sendAccountingAppUrl(props.card.uuid, isMobileConst);
  } catch (err) {
    catchErrorShow(err, t);
  }
};

</script>
